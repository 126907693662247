var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sublocation_add_row__header" },
    [
      _c(
        "line-item",
        {
          staticClass:
            "col sublocation_add_row__header-inner height-xs--8 align-items-center d-flex",
          class: { "justify-content-center": _vm.center },
        },
        [
          _vm.logo
            ? _c("div", {
                staticClass: "sublocation_add_row__header-logo",
                class: `sublocation_add_row__header-logo--${_vm.logo}`,
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "block sublocation_add_row__header-title" },
            [
              _vm._t("default", function () {
                return [_vm._v("Explorer Header")]
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }