<template>
    <div>
        <ContextTitle
            :passedActions="titleBarActions" 
            title="Sublocations"
        />

        <SublocationAddRow
            v-if="canEdit"
            logo="sublocation"
            @click.native="createSublocation"
        >
            Add new sublocation
        </SublocationAddRow>

        <template 
            v-for="sublocation in sublocations"
        >
            <SublocationRow
                :key="sublocation.sublocationID"
                :sublocation="sublocation"
                :countryCode="partner.countryCode"
                :partnerId="partner.id"
                :active="activeRows.includes(sublocation.sublocationID)"
            />
        </template>

    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import bus from '../../store/action_bus.js'
import Vue from 'vue'

import ContextTitle from '../../components/context_title.vue'
import SublocationAddRow from 'components/partners/sublocation_add_row.vue'
import SublocationRow from 'components/partners/sublocation_row.vue'

export default {
    name: 'SublocationList',
    components: {
        ContextTitle,
        SublocationAddRow,
        SublocationRow,
    },
    data() {
        return {
            activeRows: [],
        }
    }, 
    computed: {
        titleBarActions() {
            const expand = {
                type: 'expand',
                run: this.expandAll,
            }

            const collapse = {
                type: 'collapse',
                run: this.collapseAll,
            }
            
            return [expand, collapse]
        },
        partner() {
            const partnerId = this.$route.params.partner
            
            if (this.partnerList === undefined) return undefined

            return this.partnerList.find(el => el.id == partnerId)
        },
        sublocations() {
            return this.partner.cursusPartnerSubLocations
        },
        ...mapGetters('PartnerStore', [
            'partnerList',
            'canEdit'
        ])
    },
    methods: {
        expandAll() {
            this.activeRows = this.sublocations.map(el => el.sublocationID)
        },
        collapseAll() {
            this.activeRows = []
        },
        createSublocation() {
            this.$router.push({name: 'sublocationCreate'})
        }
    }
}
</script>

<style lang="scss">
</style>
