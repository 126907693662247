// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/sublocation_add.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".sublocation_add_row__header[data-v-394e269e] {\n  font-size: 14px;\n}\n.sublocation_add_row__header-title[data-v-394e269e] {\n  margin-left: 12px;\n}\n.sublocation_add_row__header-inner[data-v-394e269e] {\n  padding-left: 30px;\n  color: #93BD20;\n}\n.sublocation_add_row__header-logo[data-v-394e269e] {\n  width: 18px;\n  height: 18px;\n  background-size: contain;\n}\n.sublocation_add_row__header-logo--sublocation[data-v-394e269e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n}", ""]);
// Exports
module.exports = exports;
