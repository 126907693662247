<template>
    <div
        class="sublocation_row"
        :class="{ 'sublocation_row--active': active}"
    >
        <LineItem class="height-xs--7">
            <div 
                class="sublocation_row__line row no-gutters height-xs--inherit align-items-center"
                @click="toggle"
            >
                <div class="sublocation_row__head col-4">
                    <div 
                        class="sublocation_row__icon" 
                        :class="{'sublocation_row__icon--active': active}"
                    />

                    <div class="sublocation_row__title">{{ rowTitle }}</div>
                </div>

                <div class="sublocation_row__head col-2">
                    <div class="sublocation_row">{{ sublocation.subLocationID }}</div>
                </div>

            </div>
        </LineItem>

        <div
            class="sublocation_row__expanded row no-gutters"
            :style="{maxHeight: active ? activeHeight : 0}"
        >
            <LineItem
                class="col-12 sublocation_row__expanded__info" 
            >
                <div 
                    v-for="waypoint in sublocation.waypoints"
                    :key="waypoint.storeID"
                    class="row no-gutters sublocation_row_waypoints"
                >
                    <div class="col-2 sublocation_row_waypoints_key">
                        {{ waypoint.storeName }}
                    </div>
                    <div class="col-2 sublocation_row_waypoints_value">
                        {{ waypoint.nearGate }}
                    </div>
                </div>
            </LineItem>
            <LineItem
                v-if="canEdit"
                class="col-12 row no-gutters sublocation_row__edit_links" 
            >
                <div class="col-12">
                    <router-link
                        :to="{name: 'sublocationEdit', params: {partner:`${partnerId}`, sublocation: `${sublocation.subLocationID}`}}"
                    >Edit Sublocation</router-link>
                </div>

            </LineItem>
        </div>

    </div>
</template>

<script>
import LineItem from 'components/line_item.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'SublocationRow',
    components: {
        LineItem,
    },
    props: {
        sublocation: {
            type: Object,
            required: true,
        },
        countryCode: {
            type: String,
            required: true,
        },
        partnerId: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        
        }
    },
    computed: {
        activeHeight() {
            return '200px';
        },
        rowTitle() {
            return `${this.countryCode}_${this.sublocation.partnerShortCode} ${this.sublocation.subLocationName}`
        },
        airportName() {
            const airport = this.airports.find(el => el.airportIdent == sublocation.location)

            return airport ? airport.airportName : 'Unknown Airport'
        },
        ...mapGetters('PartnerStore', [
            'airports',
            'canEdit'
        ])
    },
    methods: {
        toggle() {
            this.active = !this.active
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.sublocation_row {
    &__line {
        background-color: #fff;
        cursor: pointer;
        padding-left: 30px;
    }

    &__head {
        display: flex;
        font-size: fz(sm);
    }

    &__icon {
        @include background-image('', 'sublocation_normal.svg', '../../img/');
        background-size: contain;
        width: spacing(sm);
        height: spacing(sm);
        margin-top: 2px;

        &--active {
            @include background-image('', 'sublocation_open.svg', '../../img/');
        }
    }

    &__title {
        margin-left: spacing(xs);
    }

    &__expanded {
        height: 346px !important;
        transition: max-height 100ms;
        background-color: #fff;
        overflow: hidden;

        &--inner {
            min-height: 10px;
        }

        &__info {
            overflow: hidden;
            padding-left: 60px !important;
            padding-top: 22px;
            padding-bottom: 22px;
            clear: both;
        }
    }

    &__edit_links {
        padding-left: 64px !important;
        padding-top: 10px;
        a {
            color: $brand_color;
        }
    }
    

    &--active {
        .sublocation_row__title {
            @include below(xs) {
                max-width: 115px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
.sublocation_row_waypoints_key {
	color: #72980A;
	font-family: Roboto;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
}
.sublocation_row_waypoints_value {
	color: #555555;
	font-family: Roboto;
	font-size: 14px;
	line-height: 16px;
}
</style>
