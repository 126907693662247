var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: "Sublocations" },
      }),
      _vm.canEdit
        ? _c(
            "SublocationAddRow",
            {
              attrs: { logo: "sublocation" },
              nativeOn: {
                click: function ($event) {
                  return _vm.createSublocation.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Add new sublocation ")]
          )
        : _vm._e(),
      _vm._l(_vm.sublocations, function (sublocation) {
        return [
          _c("SublocationRow", {
            key: sublocation.sublocationID,
            attrs: {
              sublocation: sublocation,
              countryCode: _vm.partner.countryCode,
              partnerId: _vm.partner.id,
              active: _vm.activeRows.includes(sublocation.sublocationID),
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }